/* eslint-disable no-param-reassign */
/* eslint-disable no-param-reassign */
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  scraperData: [],
  activeTab: "Flows" // Default tab
};

const ScraperSlice = createSlice({
  name: "scraper",
  initialState,
  reducers: {
    setScraperData: (state, action) => {
      state.scraperData = action.payload;
    },
    appendScraperData: (state, action) => {
      state.scraperData = [action.payload, ...state.scraperData];
    },
    deleteScraperData: (state, action) => {
      state.scraperData = state.scraperData.filter(
        (scraper) => scraper.id !== action.payload
      );
    },
    updateScraperStatus: (state, action) => {
      const { scraperId, status, s3Scraped } = action.payload;
      const scraper = state.scraperData.find(
        (scraper) => String(scraper.id) === String(scraperId)
      );
      if (scraper) {
        scraper.status = status;
        if (s3Scraped) {
          scraper.s3Scraped = s3Scraped;
        }
      }
    },
    setActiveTab: (state, action) => {
      state.activeTab = action.payload;
    }
  }
});

export const {
  setScraperData,
  appendScraperData,
  deleteScraperData,
  updateScraperStatus,
  setActiveTab
} = ScraperSlice.actions;

export default ScraperSlice.reducer;
