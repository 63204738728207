import { FaCopy, FaShare, FaTrash } from "react-icons/fa";
import toastr from "../Config/toastrConfig";
import { store } from "../Store";
import { resetUndo } from "../Store/slices/workflowSlice";

const extractPlaceholders = (str, isImportedData) => {
  const regex = isImportedData ? /{(\w+)}|{\w*$/g : /{(\w+)}/g;
  const matches = [];
  let match = regex.exec(str);

  while (match !== null) {
    if (isImportedData) {
      matches.push(match[1]);
    } else {
      matches.push({ name: match[1], enabled: true, checked: false });
    }
    match = regex.exec(str);
  }

  return matches;
};

const updatePromptStates = (placeholders, setPromptStates) => {
  setPromptStates((prevPromptStates) => {
    const newPromptStates = [...prevPromptStates];
    placeholders.forEach((placeholder) => {
      const newPlaceholder = {
        name: placeholder,
        enabled: true,
        checked: false
      };
      if (
        !newPromptStates.some((state) => state.name === newPlaceholder.name)
      ) {
        newPromptStates.push(newPlaceholder);
      }
    });
    return newPromptStates;
  });
};

const removePromptStates = (placeholders, setPromptStates) => {
  setPromptStates((prevPromptStates) =>
    prevPromptStates.filter((state) => !placeholders.includes(state.name))
  );
};

const processImportedData = (
  importedData,
  setPromptStates,
  setFieldValues,
  setEditorContents,
  setNodes,
  setEdges,
  setFileDetails,
  removeNode,
  handleChange,
  openPromptModal,
  handleParentFileDetailUpdate,
  duplicateNode,
  toggleMinimize,
  minimizedNodes,
  handleNodeUpdate,
  credentials,
  activeNodeId
) => {
  try {
    if (
      !Array.isArray(importedData) ||
      !importedData.every(
        (item) =>
          Object.prototype.hasOwnProperty.call(item, "id") &&
          Object.prototype.hasOwnProperty.call(item, "data")
      )
    ) {
      throw new Error("Invalid JSON structure");
    }

    const edges = importedData.flatMap((node) => node.edges || []);

    const nodes1 = importedData.map((node) => {
      const updatedParams = node.data.params
        ? Object.keys(node.data.params).reduce((acc, key) => {
            acc[key] = {
              ...node.data.params[key],
              default: node.fieldValues?.[key] || node.data.params[key].default
            };
            return acc;
          }, {})
        : {};

      const defaultApiKeys = getFirstApiKeyForEachCategory(credentials);
      const newFieldValues = node.fieldValues;
      Object.keys(newFieldValues)?.forEach((key) => {
        if (Object.prototype.hasOwnProperty.call(defaultApiKeys, key)) {
          newFieldValues[key] = defaultApiKeys[key];
        }
      });

      return {
        ...node,
        data: {
          ...node.data,
          onDelete: removeNode,
          onChange: handleChange,
          params: updatedParams,
          fieldValues: newFieldValues,
          dependentParams: node.data.dependentParams || {}, // Ensure dependent_params is included
          filesUploaded: node.files_uploaded || [], // Ensure files_uploaded is included
          setEditorContents,
          openPromptModal,
          handleParentFileDetailUpdate,
          duplicateNode,
          toggleMinimize,
          minimizedNodes,
          handleNodeUpdate,
          activeNodeId
        }
      };
    });

    const newEditorContents = nodes1.reduce((acc, node) => {
      if (node.type === "conditional") {
        acc[node.id] = node.code || "";
      }
      return acc;
    }, {});

    const nodes = nodes1.map((node) => ({
      ...node,
      data: {
        ...node.data,
        editorContents: newEditorContents || {}
      }
    }));

    setEditorContents(newEditorContents);
    setNodes(nodes);
    setEdges(edges);

    // Update fileDetails based on files_uploaded in each node
    const newFileDetails = nodes1.reduce((acc, node) => {
      acc[node.id] = node.files_uploaded || [];
      return acc;
    }, {});
    setFileDetails(newFileDetails); // Set the fileDetails state here

    // Ensure the fieldValues are set
    const newFieldValues = nodes.reduce((acc, node) => {
      acc[node.id] =
        Object.keys(node.fieldValues)?.length > 0
          ? node.fieldValues // Use node.fieldValues if not empty
          : node.data.fieldValues || {}; // Otherwise, fall back to node.data.fieldValues
      return acc;
    }, {});
    setFieldValues(newFieldValues);

    // Extract and update prompt states
    const allPlaceholders = nodes.flatMap((node) =>
      Object.values(node.fieldValues || {}).flatMap((value) =>
        extractPlaceholders(value, true)
      )
    );
    updatePromptStates(allPlaceholders, setPromptStates);
    store.dispatch(resetUndo());
  } catch (error) {
    toastr.error("Invalid JSON data");
    console.error("Invalid JSON data:", error);
  }
};

const disableScroll = () => {
  document.body.style.overflow = "hidden";
};

const enableScroll = () => {
  document.body.style.overflow = "";
};

const convertObjectToString = (obj, indent = 0) => {
  let result = "";

  Object.keys(obj).forEach((key) => {
    if (typeof obj[key] === "object" && obj[key] !== null) {
      result += `${" ".repeat(indent)}${key} :\n${convertObjectToString(
        obj[key],
        indent + 2
      )}`;
    } else {
      result += `${" ".repeat(indent)}${key} : ${obj[key]}\n`;
    }
  });

  return result;
};

const getToggledValue = (toggleField, userToToggle) => {
  if (toggleField === "role") {
    return userToToggle[toggleField] === "ADMIN" ? "USER" : "ADMIN";
  }

  return !userToToggle[toggleField];
};

// Function to stringify arrays and compare them
const isArraysEqual = (arr1, arr2) => {
  // Convert arrays to JSON strings
  const jsonStr1 = JSON.stringify(arr1);
  const jsonStr2 = JSON.stringify(arr2);

  // Compare the JSON strings
  return jsonStr1 === jsonStr2;
};

const getPythonCode = (tweeks, flowName) => {
  return `
from flexagents import load_flow_from_json

TWEAKS = ${JSON.stringify(tweeks)}

flow = load_flow_from_json("${flowName}.json", tweaks=TWEAKS)

# Now you can use it like any chain
inputs = {"query":""}

flow(inputs)`;
};

const getPythonApi = (tweeks, flowId) => {
  return `
import requests

BASE_API_URL = "${process.env.REACT_APP_FLOW_URL}"
FLOW_ID = "${flowId}"

TWEAKS = ${JSON.stringify(tweeks)}

def run_flow(inputs: dict, flow_id: str, tweaks: Optional[dict] = None) -> dict:
    """
    Run a flow with a given message and optional tweaks.

    :param inputs: The inputs to send to the flow
    :param flow_id: The ID of the flow to run
    :return: The JSON response from the flow
    """
    # flow_id should only be passed as a query parameter, not in the payload.
    api_url = f"{BASE_API_URL}/chats/widget?flowId={flow_id}"

    payload = {"chatData": inputs}
    headers = {
      'Content-Type': 'application/json'
    }
    if tweaks:
      payload["tweaks"] = tweaks
    response = requests.post(api_url, json=payload, headers=headers)
    return response.json()

# Setup the inputs for the flow
inputs = {"query":""}
# Run the flow with the inputs and flow_id
print(run_flow(inputs, flow_id=FLOW_ID, tweaks=TWEAKS))`;
};

const getCurl = (tweeks, flowId) => {
  return `
  curl -X POST \\
   ${process.env.REACT_APP_FLOW_URL}/chats/widget?flowId=${flowId} \\
   -H 'accept: application/json' \\
   -H 'Content-Type: application/json' \\
   -H 'x-api-key: <your api key>' \\
   -d '{"chatData": {"query":""}, "tweaks": ${JSON.stringify(tweeks)}}'`;
};

const getComponentActions = (handleShare, handleDelete) => [
  { label: "Share", icon: FaShare, onClick: handleShare },
  {
    label: "Delete",
    icon: FaTrash,
    onClick: handleDelete
  }
];

const getFlowActions = (handleDuplicate, handleDelete) => [
  { label: "Duplicate", icon: FaCopy, onClick: handleDuplicate },
  {
    label: "Delete",
    icon: FaTrash,
    onClick: handleDelete
  }
];

const getScrapperActions = (handleDelete) => [
  {
    label: "Delete",
    icon: FaTrash,
    onClick: handleDelete
  }
];

const getNodeType = (category) => {
  if (category === "Saved") {
    return "dynamic";
  }

  if (category === "Agents") {
    return "agentic";
  }

  return "custom";
};

const transformComponentData = (rawData) => {
  const transformedData = {};

  // // Get the ordered_list from rawData and exclude it from the final transformedData
  const orderList = rawData?.ordered_list || [];

  // Iterate over the ordered list to maintain the desired order
  orderList.forEach((category) => {
    if (rawData[category] && category !== "ordered_list") {
      // Ensure 'ordered_list' is not included in the final result
      const { icon, components } = rawData[category]; // Destructure icon and components from each category

      const transformedComponents = components.map((node) => {
        const { params, ...restNode } = node;

        const paramsSeparator = params.separator;
        // Transform separator param
        if (params && paramsSeparator) {
          paramsSeparator.default = paramsSeparator.default.replace(
            "\n",
            "\\n"
          );
        }

        // Transform params defaults to string using destructuring
        const transformedParams = Object.keys(params).reduce((acc, key) => {
          const { default: defaultValue, ...restParam } = params[key]; // Destructure default and other param properties
          acc[key] = {
            ...restParam,
            default: defaultValue?.toString() // Convert default to string
          };
          return acc;
        }, {});

        // Return the transformed node with destructuring
        return {
          ...restNode, // Include the rest of the node properties
          params: transformedParams, // Transformed params
          type: getNodeType(category),
          icon // Assign the icon from the category
        };
      });

      // Only include the category if it has components
      if (transformedComponents.length > 0) {
        transformedData[category] = {
          icon, // Category icon
          components: transformedComponents // Transformed components
        };
      }
    }
  });

  return transformedData;
};

const generateChatWidgetScript = (
  flowId,
  selectedFlow,
  chatInputs,
  chatInputField
) => {
  return `
  <script src="https://flexagents-docs.s3.us-east-1.amazonaws.com/bundle.min.js"></script>

  <!-- chat_inputs: Stringified JSON with all the input keys and its values. The value of the key that is defined
  as chat_input_field will be overwritten by the chat message.
  chat_input_field: Input key that you want the chat to send the user message with. -->

  <flex-agents-chat
    window_title="${selectedFlow.name}"
    flow_id="${flowId}"
    show_attachment=${selectedFlow.showDocumentLoader}
    chat_inputs='${chatInputs}'
    chat_input_field="${chatInputField}"
    host_url="${process.env.REACT_APP_FLOW_URL}"
  ></flex-agents-chat>`;
};

// keyPressUtils.js

const handleDeleteKeyPress = (activeNodeId, id, onDelete) => {
  const handleKeyPress = (event) => {
    if (event.key === "Delete" && activeNodeId === id) {
      onDelete(id); // Trigger node deletion
    }
  };

  document.addEventListener("keydown", handleKeyPress);

  return () => {
    document.removeEventListener("keydown", handleKeyPress);
  };
};

const truncateFileName = (name, charLength) => {
  return name.length > charLength
    ? `${name.substring(0, charLength)}...`
    : name;
};

const generateRandomString = (length = 8) => {
  return Math.random()
    .toString(36)
    .substring(2, 2 + length); // Generates a random alphanumeric string
};

const urlBase64ToUint8Array = (base64Url) => {
  const base64 = base64Url.replace(/-/g, "+").replace(/_/g, "/");
  const binaryString = window.atob(base64);
  const bytes = new Uint8Array(binaryString.length);
  for (let i = 0; i < binaryString.length; i += 1) {
    bytes[i] = binaryString.charCodeAt(i);
  }
  return bytes;
};

const getStatusMessage = (status) => {
  switch (status) {
    case "LINK_EXTRACTION_IN_PROGRESS":
      return "Extracting links";
    case "LINK_EXTRACTION_COMPLETE":
      return "Links successfully extracted";
    case "SCRAPPING_DATA_IN_PROGRESS":
      return "Scraping data in progress";
    case "SCRAPPING_COMPLETE":
      return "Scraping completed";
    case "SCRAPPING_CANCELLED":
      return "Scraping process cancelled";
    case "LINK_EXTRACTION_FAILED":
      return "Link extraction failed";
    case "SCRAPPING_FAILED":
      return "Scraping failed";
    default:
      return "Status unknown.";
  }
};

const generateDeviceId = () => {
  const { userAgent } = window.navigator;
  // Generate a random string
  const randomString =
    Math.random().toString(36).substring(2, 14) +
    Math.random().toString(36).substring(2, 14);

  // Combine the userAgent with a random string to create a unique device ID
  const deviceId = `${userAgent}-${randomString}`;

  return deviceId;
};

const extractCredentialsList = (type, credentialsList) => {
  if (!credentialsList || !Array.isArray(credentialsList)) {
    console.error("Invalid credentialsList: Expected an array.");
    return [];
  }

  const typeToKeyMap = {
    api_key: "OPEN_AI_KEY",
    langsmith_api_key: "LANG_SMITH_KEY"
  };

  const keyType = typeToKeyMap[type];

  return credentialsList.filter((item) => item.key_type === keyType) || [];
};

const getFirstApiKeyForEachCategory = (credentials) => {
  const result = {};

  credentials.forEach((credential) => {
    let resultKey;

    if (credential.key_type === "OPEN_AI_KEY") {
      resultKey = "api_key";
    } else if (credential.key_type === "LANG_SMITH_KEY") {
      resultKey = "langsmith_api_key";
    }

    // Only add the first occurrence of each key_type
    if (resultKey && !result[resultKey]) {
      result[resultKey] = credential.key_value;
    }
  });

  return result;
};

export {
  extractPlaceholders,
  updatePromptStates,
  removePromptStates,
  processImportedData,
  disableScroll,
  enableScroll,
  convertObjectToString,
  getToggledValue,
  getPythonCode,
  getPythonApi,
  getCurl,
  getComponentActions,
  getFlowActions,
  getScrapperActions,
  transformComponentData,
  generateChatWidgetScript,
  isArraysEqual,
  handleDeleteKeyPress,
  truncateFileName,
  generateRandomString,
  urlBase64ToUint8Array,
  getStatusMessage,
  generateDeviceId,
  extractCredentialsList,
  getFirstApiKeyForEachCategory
};
